import { Box, Grid } from '@mui/material'
import styled from '@emotion/styled'
import { Link, useLocation, useParams } from 'react-router-dom'
import {
  INFO_COLOR, MAX_MOBILE_DIMENSIONS, SUPPORTED_LANGUAGES, MAX_TABLET_DIMENSIONS,
  LOGO_CRANIAL_URL,
} from '../app/constants'
import usePathTranslation from '../hooks/pathTranslation'

const LOGO_WIDTH = 160

const LanguageSelector = ({ language, changeLanguage, t }: {
  language: string,
  changeLanguage: (lang: string) => void,
  t: (key: string) => string
}) => (
  <StyledLanguageGrid
    alignItems="center"
    direction="row"
    display="flex"
    item
  >
    <span style={{ color: 'gray' }}>
      {t('language')}
      :
    </span>
    <StyledHorizontalList>
      {Object.keys(SUPPORTED_LANGUAGES).map((lang, idx) => (
        <>
          <li key={lang}>
            <button
              onClick={() => changeLanguage(lang)}
              style={{
                color: 'gray',
                fontWeight: language === lang ? 'bold' : 'normal',
                cursor: 'pointer',
                border: 'none',
                background: 'none',
                padding: 0,
              }}
              type="button"
            >
              {SUPPORTED_LANGUAGES[lang as keyof typeof SUPPORTED_LANGUAGES]}
            </button>
          </li>
          {idx < Object.keys(SUPPORTED_LANGUAGES).length - 1 && (
            <span style={{ color: 'gray' }}>|</span>
          )}
        </>
      ))}
    </StyledHorizontalList>
  </StyledLanguageGrid>
)

const TranslationHeader = () => {
  const { changeLanguage, language, t } = usePathTranslation()

  return (
    <Grid
      container
      justifyContent="center"
    >
      <SpaceBetweenGrid>
        <HeaderWrapper>
          <Grid item>
            <CenteredBox>
              <Box
                alt="logo"
                component="img"
                src={LOGO_CRANIAL_URL}
                width={LOGO_WIDTH}
              />
            </CenteredBox>
          </Grid>
        </HeaderWrapper>
        <LanguageSelector
          changeLanguage={changeLanguage}
          language={language}
          t={t}
        />
      </SpaceBetweenGrid>
    </Grid>
  )
}

const HeaderWithChangeAppointmentLink = () => {
  const { changeLanguage, language, t } = usePathTranslation()
  return (
    <Grid
      container
      justifyContent="end"
    >
      <SpaceWithAppLink>
        <CenteredBox>
          <Box
            alt="logo"
            component="img"
            src={LOGO_CRANIAL_URL}
            width={LOGO_WIDTH}
          />
        </CenteredBox>
        <HeaderWrapper>
          <LanguageSelector
            changeLanguage={changeLanguage}
            language={language}
            t={t}
          />
          <ChangeAppointmentLink
            to="./reschedule"
          >
            {t('header.change_appointment')}
          </ChangeAppointmentLink>
        </HeaderWrapper>
      </SpaceWithAppLink>
    </Grid>
  )
}

const Header = () => {
  const location = useLocation()
  const { lang } = useParams()

  const isRoot = location.pathname === '/'
    || (lang && Object.keys(SUPPORTED_LANGUAGES).includes(lang) && location.pathname === `/${lang}/`)

  return (
    <StyledHeader>
      {!isRoot && <TranslationHeader />}
      {isRoot && <HeaderWithChangeAppointmentLink />}
    </StyledHeader>
  )
}
const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  column-gap: 1rem;

  @media (min-width: ${MAX_MOBILE_DIMENSIONS}px) {
    width: 100%;
    grid-template-columns: 1fr ${LOGO_WIDTH}px 1fr;
    grid-column-gap: 2rem;
  }
`

const ChangeAppointmentLink = styled(Link)`
  color: ${INFO_COLOR};
`

const CenteredBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledHorizontalList = styled.ul`
  display: flex;
  gap: 10px;
  list-style: none;
  padding-left: 10px;
`

const SpaceBetweenGrid = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;

  @media (max-width: ${MAX_TABLET_DIMENSIONS}px) {
    width: 100%;
  }

  @media (max-width: ${MAX_MOBILE_DIMENSIONS}px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const SpaceWithAppLink = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;

  @media (max-width: ${MAX_TABLET_DIMENSIONS}px) {
    width: 100%;
  }

  @media (max-width: ${MAX_MOBILE_DIMENSIONS}px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const StyledHeader = styled.header`
  background: #fff;
  box-shadow: 0 2px 4px rgb(0 0 0 / 25%);
  padding: 15px;

  @media (min-width: ${MAX_MOBILE_DIMENSIONS}px) {
    padding: 30px;
  }
`

const StyledLanguageGrid = styled(Grid)`
  justify-content: center;

  @media (min-width: ${MAX_MOBILE_DIMENSIONS}px) {
    justify-content: flex-end;
  }
`

export default Header
