import { Box } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import AppBar from '../../../components/AppBar'
import MapComponent, { NextButtonContext } from './map'
import ViewContainer from '../../../components/ViewContainer'
import usePathTranslation from '../../../hooks/pathTranslation'

const ClinicFinder = () => {
  const { t } = usePathTranslation()
  const reschedule: boolean = useLocation().state?.reschedule ?? false

  const serviceCenterID = useSelector((state: any) => state.appointmentInfo.ServiceCenterID)
  const addressString: string | undefined = `${useSelector((state: any) => state.zipCode.zip)}`
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true)
  const navigate = useNavigate()

  const handleOnClickBack = (event: any) => {
    event.preventDefault()

    navigate(-1)
  }

  const handleForm = (event: any) => {
    event.preventDefault()

    navigate('../', { relative: 'path' })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (!serviceCenterID) return
    navigate('../select-appointment', { relative: 'path', state: { reschedule } })
  }

  return (
    <ViewContainer>
      <AppBar
        description={t('self_scheduling.clinic_finder.description')}
        maxProgress={4}
        progress={2}
        title={t('self_scheduling.clinic_finder.title')}
      />
      <Box>
        <NextButtonContext.Provider value={setIsNextButtonDisabled}>
          <MapComponent
            addressString={addressString}
            handleOnClickBack={reschedule ? handleOnClickBack : handleForm}
            handleSubmit={handleSubmit}
            isNextButtonDisabled={isNextButtonDisabled}
            serviceCenterId={reschedule ? serviceCenterID : undefined}
          />
        </NextButtonContext.Provider>
      </Box>
    </ViewContainer>
  )
}

export default ClinicFinder
