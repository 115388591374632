import {
  Box, Grid, Typography, CircularProgress,
} from '@mui/material'
import styled from '@emotion/styled'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useState } from 'react'
import dayjs from 'dayjs'
import LocationIcon from '../../../assets/location'
import ClipboardIcon from '../../../assets/clipboard'
import {
  CONTACT_NUMBER,
  DATE_PATTERNS,
} from '../../../app/constants'
import AppBar from '../../../components/AppBar'
import NextButton from '../../../components/NextButton'
import ViewContainer from '../../../components/ViewContainer'
import BackButton from '../../../components/BackButton'
import { setZipCode } from '../../../store/zipCode'
import { setServiceCenterID } from '../../../store/appointmentInfo'
import cancelAppointment from '../../../api/cancelAppointment'
import useDialog from '../../../hooks/dialog'
import Dialog from '../../../components/Dialog'
import usePathTranslation from '../../../hooks/pathTranslation'

const DescriptionTitle = ({ text }: { text: string }) => (
  <Box component="span">
    {text}
  </Box>
)

const ButtonContainer = (props: {
  isLoading: any;
  handleReschedule: () => void;
  handleCancel: () => Promise<void>;
  handleOnClickBack: (event: any) => void;
}) => {
  const { t } = usePathTranslation()
  const {
    isLoading, handleReschedule, handleCancel, handleOnClickBack,
  } = props
  return isLoading ? (
    <LoadingWrapper>
      <LoadingBox
        size={50}
      />
    </LoadingWrapper>
  ) : (
    <ButtonsDiv>
      <NextButton
        isLoading={isLoading}
        onClick={handleReschedule}
        secondary
        text={t('self_scheduling.scheduled_appointment_info.reschedule_button')}
      />
      <NextButton
        isLoading={isLoading}
        onClick={handleCancel}
        text={t('self_scheduling.scheduled_appointment_info.cancel_button')}
      />
      <BackButton onClick={handleOnClickBack} />
    </ButtonsDiv>
  )
}

const ScheduledAppointmentInformation = () => {
  const { lang } = useParams()
  dayjs.locale(lang)
  const { t } = usePathTranslation()
  const confirmHook = useDialog()
  const alertHook = useDialog()
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const navigate = useNavigate()

  const identifyPatient = useSelector((state: any) => state.identifyPatient)
  const anconAppointment = identifyPatient.appointmentList.filter(
    (appointment: any) => appointment.Reason === 'ANCON',
  )[0]

  const clinicName = anconAppointment.ServiceCenter.Name.replace('Cranial Technologies Inc - ', '')

  const startDateTime = dayjs(anconAppointment.DateTime)
  const handleOnClickBack = (event: any) => {
    event.preventDefault()
    navigate('../reschedule', { relative: 'path' })
  }

  const handleCancel = async () => {
    confirmHook.setDialog({
      title: t('self_scheduling.scheduled_appointment_info.handle_cancel_title'),
      description: t('self_scheduling.scheduled_appointment_info.handle_cancel_description'),
      buttonHandler: () => {
        setLoading(true)
        confirmHook.closeDialog()
        cancelAppointment(identifyPatient.appointmentList).then(() => {
          navigate('../appointment-cancelled', { relative: 'path' })
        }).catch(() => {
          alertHook.setDialog({
            title: t('self_scheduling.scheduled_appointment_info.error_dialog_title'),
            description: t('self_scheduling.scheduled_appointment_info.error_dialog_description', {
              clinicNumber: CONTACT_NUMBER.other,
            }),
            buttonHandler: () => {
              navigate('../', { relative: 'path' })
            },
          })
        }).finally(() => {
          setLoading(false)
        })
      },
    })
  }

  const handleReschedule = () => {
    dispatch(setZipCode(identifyPatient.zipCode))
    dispatch(setServiceCenterID(anconAppointment?.ServiceCenter?.ID))
    navigate('../find-clinic', { relative: 'path', state: { reschedule: true } })
  }

  return (
    <ViewContainer>
      <AppBar
        description={<DescriptionTitle text={t('self_scheduling.scheduled_appointment_info.description_title')} />}
        maxProgress={4}
        progress={2}
        title={t('self_scheduling.scheduled_appointment_info.title')}
      />
      <StyledRow
        container
        rowGap={3}
      >
        <Grid
          desktop={1}
          item
          mobile={0}
          tablet={1}
        />
        <Grid
          desktop={5}
          item
          mobile={12}
          sx={{
            justifyContent: 'center',
            display: 'flex',
          }}
          tablet={5}
        >
          <StyledBox>
            <ClipboardIcon />
            <StyledCardSection>
              <StyledTitle>
                {t('self_scheduling.scheduled_appointment_info.evaluation_time')}
              </StyledTitle>
              <StyledDescription>
                {startDateTime.format(DATE_PATTERNS.long_date)}
                {' '}
                {t('self_scheduling.scheduled_appointment_info.at_time')}
                {' '}
                {startDateTime.format(DATE_PATTERNS.time_am_pm)}
              </StyledDescription>
            </StyledCardSection>
          </StyledBox>
        </Grid>
        <Grid
          desktop={5}
          item
          mobile={12}
          sx={{
            justifyContent: 'center',
            display: 'flex',
          }}
          tablet={5}
        >
          <StyledBox>
            <LocationIcon />
            <StyledCardSection>
              <StyledTitle>{t('self_scheduling.scheduled_appointment_info.clinic_location')}</StyledTitle>
              <StyledDescription>
                <span style={{ whiteSpace: 'nowrap' }}>{clinicName}</span>
                <span style={{ whiteSpace: 'nowrap' }}>{anconAppointment.Address}</span>
              </StyledDescription>
            </StyledCardSection>
          </StyledBox>
        </Grid>
        <Grid
          desktop={1}
          item
          mobile={0}
          tablet={1}
        />
      </StyledRow>
      <ButtonContainer
        handleCancel={handleCancel}
        handleOnClickBack={handleOnClickBack}
        handleReschedule={handleReschedule}
        isLoading={isLoading}
      />
      <Dialog
        buttonText={t('self_scheduling.scheduled_appointment_info.confirm_cancellation')}
        dialogHook={confirmHook}
      />
      <Dialog
        buttonText={t('self_scheduling.scheduled_appointment_info.contact_us')}
        dialogHook={alertHook}
      />
    </ViewContainer>
  )
}

const StyledCardSection = styled(Box)`
  display: grid;
  justify-content: center;
  align-items: center;
`

const StyledRow = styled(Grid)`
  justify-content: center;
  padding-bottom: 30px;
  padding-top: 30px;
  flex-direction: column;
  align-items: center;
`

const StyledTitle = styled(Typography)`
  font-size: 16px;
  font-weight: bold;
`

const StyledDescription = styled(Typography)`
  font-size: 14px;
  display: flex;
  flex-direction: column;
`

const StyledBox = styled(Box)`
  max-width: 200px;
`

const ButtonsDiv = styled(Box)`
  display: flex;
  align-self: center;
  flex-direction: column;
`

const LoadingWrapper = styled(Box)`
  display: flex;
  align-self: center;
  flex-direction: column;
  padding-bottom: 10%;
`

const LoadingBox = styled(CircularProgress)`
  position: absolute;
  display: flex;
  align-self: center;
  margin-bottom: 1rem;
`

export default ScheduledAppointmentInformation
