import { Box } from '@mui/material'
import styled from '@emotion/styled'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import dayjs from 'dayjs'
import { AxiosError } from 'axios'
import { setIdentifyPatient } from '../../../store/identifyPatient'
import AppBar from '../../../components/AppBar'
import NextButton from '../../../components/NextButton'
import ViewContainer from '../../../components/ViewContainer'
import BackButton from '../../../components/BackButton'
import { IIdentifyPatientValues } from '../../../app/types'
import getPatientAppointments from '../../../api/getPatientAppointments'
import { setPersonID } from '../../../store/reschedule'
import useDialog from '../../../hooks/dialog'
import Dialog from '../../../components/Dialog'
import { DATE_PATTERNS } from '../../../app/constants'
import PatientBasicInfoForm from '../../../components/PatientBasicInfoForm'
import usePathTranslation from '../../../hooks/pathTranslation'

const DescriptionTitle = ({ text }: { text: string }) => (
  <Box component="span">
    {text}
  </Box>
)

const DescriptionPatientInformation = ({ text }: { text: string }) => (
  <Box component="span">
    {text}
  </Box>
)

const IdentifyPatient = () => {
  const { t } = usePathTranslation()
  const alertHook = useDialog()
  const [isLoading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const identifyPatient = useSelector((state: any) => state.identifyPatient)

  const defaultValues = {
    patientFirstName: identifyPatient.patientFirstName || '',
    patientLastName: identifyPatient.patientLastName || '',
  }

  const {
    control, formState, handleSubmit,
  } = useForm<IIdentifyPatientValues>({
    defaultValues,
  })
  const { errors } = formState

  const handleOnClickBack = (event: any) => {
    event.preventDefault()
    navigate('../', { relative: 'path' })
  }

  const handleOnFinish = () => (values: IIdentifyPatientValues) => {
    setLoading(true)
    getPatientAppointments(
      {
        ...values,
        dateOfBirth: dayjs(values.dateOfBirth).format(DATE_PATTERNS.short_date),
      },
    ).then((res) => {
      dispatch(setIdentifyPatient(
        {
          ...values,
          patientZipCode: res.zipcode,
          appointmentList: res.appointment_list,
        },
      ))
      dispatch(setPersonID(res.person_id))
      navigate('../scheduled-appointment-information', { relative: 'path' })
    }).catch((error: AxiosError) => {
      let errorMessage = 'Error finding patient or appointments'
      if (error.response?.status === 404) {
        errorMessage = 'No error: No appointment found'
      }
      alertHook.setDialog({
        title: t('self_scheduling.identify_patient.title_patient_not_found'),
        description: t('self_scheduling.identify_patient.description_patient_not_found', {
          patientFirstName: values.patientFirstName,
          patientLastName: values.patientLastName,
        }),
        buttonHandler: () => {
          navigate('../contact-form', { relative: 'path', state: { messageState: 'No appointment found', error: errorMessage } })
          alertHook.closeDialog()
        },
      })
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <ViewContainer>
      <AppBar
        description={<DescriptionTitle text={t('self_scheduling.identify_patient.description')} />}
        maxProgress={4}
        progress={1}
        title={t('self_scheduling.identify_patient.title')}
      />
      <StyledBox
        component="form"
        onSubmit={handleSubmit(handleOnFinish())}
      >
        <PatientBasicInfoForm
          control={control}
          description={<DescriptionPatientInformation text={t('self_scheduling.identify_patient.description')} />}
          errors={errors}
          required
        />

        <NextButton
          isLoading={isLoading}
          secondary
          text={t('self_scheduling.identify_patient.search_button')}
        />
        <BackButton onClick={handleOnClickBack} />
      </StyledBox>
      <Dialog
        buttonText={t('self_scheduling.scheduled_appointment_info.contact_us')}
        dialogHook={alertHook}
      />
    </ViewContainer>
  )
}

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
`

export default IdentifyPatient
