import { Typography, Link } from '@mui/material'
import { Trans } from 'react-i18next'
import { CONTACT_NUMBER, SUB_COLOR_LIGHT } from '../app/constants'
import usePathTranslation from '../hooks/pathTranslation'

const ContactNumber = () => {
  const { t } = usePathTranslation()

  return (
    <Typography
      style={{
        fontSize: '14px',
        paddingTop: '15px',
        color: SUB_COLOR_LIGHT,
      }}
    >
      <Trans
        components={
          [<Link
            href={`tel:${CONTACT_NUMBER.value}`}
            style={{ whiteSpace: 'nowrap' }}
          />]
        }
        i18nKey="footer.clinic_number_text"
        t={t}
        values={{ clinicNumber: CONTACT_NUMBER.description }}
      />
    </Typography>
  )
}

export default ContactNumber
