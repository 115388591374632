import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import NextButton from '../../../components/NextButton'
import ViewContainer from '../../../components/ViewContainer'
import people from '../../../assets/images/zion-smith.jpg'
import { DISPLAY_FONT_FAMILY } from '../../../app/constants'
import {
  CenteredColumn, StyledDescription, VerticalSpacer, ButtonWrapper,
} from '../../../app/styles'
import usePathTranslation from '../../../hooks/pathTranslation'

const AppointmentInformation = () => {
  const { t } = usePathTranslation()
  const navigate = useNavigate()

  return (
    <ViewContainer>
      <CenteredColumn>
        <Box
          alt="people"
          component="img"
          src={people}
          width="75%"
        />
        <Typography
          sx={{
            fontFamily: DISPLAY_FONT_FAMILY,
            fontWeight: 600,
            padding: '10px 0',
          }}
          variant="h5"
        >
          {t('self_scheduling.appointment_cancelled.title')}
        </Typography>
        <StyledDescription>
          {t('self_scheduling.appointment_cancelled.description')}
        </StyledDescription>

        <VerticalSpacer />
        <ButtonWrapper>
          <NextButton
            onClick={() => { navigate('../', { relative: 'path' }) }}
            secondary
            text={t('self_scheduling.appointment_cancelled.button')}
          />
        </ButtonWrapper>
      </CenteredColumn>
    </ViewContainer>
  )
}

export default AppointmentInformation
