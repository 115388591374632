import { useSelector } from 'react-redux'
import { IFormItem, FormInputProps, IStates } from '../../../app/types'
import FormInputText from '../../../components/FormInputText'
import FormSection from '../../../components/FormSection'
import FormInputDropdown from '../../../components/FormInputDropdown'
import { STATE_CENTER_OPTIONS, STATE_OPTIONS } from '../../../app/constants'
import usePathTranslation from '../../../hooks/pathTranslation'

const ContactForm = ({
  control,
  errors,
  required,
  stateCode,
}: FormInputProps) => {
  const { t } = usePathTranslation()
  const stateOptions = useSelector(
    (state: any) => state.serviceCenterByState?.serviceCenterByState,
  )

  const stateOptionsCenter: {
    [key: string]: { value: string; label: string }[];
  } = stateOptions.reduce((acc: any, { value, centers }: IStates) => {
    const centerArray = centers.map((center) => ({
      value: center,
      label: center,
    }))
    acc[value] = centerArray
    return acc
  }, {})

  const contactDetails: IFormItem[] = [
    {
      inputComponent: (
        <FormInputDropdown
          control={control}
          errors={errors}
          name="stateCode"
          options={stateOptions.length > 0 ? stateOptions : STATE_OPTIONS}
          placeholder={t('self_scheduling.contact_form.state')}
          required={required}
        />
      ),
    },
    {
      inputComponent: (
        <FormInputDropdown
          control={control}
          errors={errors}
          isDisabled={!stateCode}
          name="clinicLocation"
          options={
            (Object.keys(stateOptionsCenter).length > 0
              ? stateOptionsCenter[stateCode] : STATE_CENTER_OPTIONS[stateCode]) ?? []
          }
          placeholder={t('self_scheduling.contact_form.clinic_location')}
          required={required}
        />
      ),
    },
    {
      inputComponent: (
        <FormInputText
          control={control}
          errors={errors}
          name="fullName"
          placeholder={t('self_scheduling.contact_form.full_name')}
          required={required}
        />
      ),
    },
    {
      inputComponent: (
        <FormInputText
          control={control}
          errors={errors}
          helperText={t('self_scheduling.contact_form.email_example')}
          name="email"
          placeholder={t('self_scheduling.contact_form.email')}
          required={required}
          type="email"
        />
      ),
    },
    {
      inputComponent: (
        <FormInputText
          control={control}
          errors={errors}
          helperText={t('self_scheduling.contact_form.phone_example')}
          name="phoneNumber"
          placeholder={t('self_scheduling.contact_form.phone')}
          required={required}
          type="tel"
        />
      ),
    },
    {
      inputComponent: (
        <FormInputText
          control={control}
          errors={errors}
          isMultiline
          name="message"
          placeholder={t('self_scheduling.contact_form.message')}
        />
      ),
    },
  ]

  return (
    <FormSection
      data={contactDetails}
      title={t('self_scheduling.contact_form.contact_information')}
    />
  )
}

export default ContactForm
