import { Box, Link, Typography } from '@mui/material'
import { Trans } from 'react-i18next'
import { CONTACT_NUMBER, SUB_COLOR } from '../app/constants'
import usePathTranslation from '../hooks/pathTranslation'

const ClinicNumberComponent = () => {
  const { t } = usePathTranslation()
  return (
    <Box
      component="footer"
      sx={{
        padding: '25px 0',
      }}
    >
      <Typography style={{
        color: SUB_COLOR,
        fontSize: '14px',
      }}
      >
        <Trans
          components={
            [<Link
              href={`tel:${CONTACT_NUMBER.value}`}
              style={{ whiteSpace: 'nowrap' }}
            />]
          }
          i18nKey="footer.clinic_number_text"
          t={t}
          values={{ clinicNumber: CONTACT_NUMBER.description }}
        />
      </Typography>
    </Box>
  )
}

export default ClinicNumberComponent
