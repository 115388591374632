import {
  Box,
  Link,
  Modal, Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import CloseIcon from '@mui/icons-material/Close'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { INFO_COLOR } from '../app/constants'
import NextButton from './NextButton'
import { DialogHook } from '../hooks/dialog'
import usePathTranslation from '../hooks/pathTranslation'

export interface DialogProps {
  dialogHook: DialogHook,
  buttonText?: string,
}

const Dialog = ({ dialogHook, buttonText }: DialogProps) => {
  const { t } = usePathTranslation()
  const navigate = useNavigate()
  const navigateAppointments = () => {
    navigate('../scheduled-appointment-information', { relative: 'path' })
  }
  return (
    <Modal
      aria-describedby="modal-modal-description"
      aria-labelledby="modal-modal-title"
      onClose={dialogHook.closeDialog}
      open={dialogHook.isAlertOpen}
    >
      <ModalContent>
        <CloseButtonWrapper>
          <CloseIcon
            onClick={dialogHook.closeDialog}
            style={{
              color: INFO_COLOR,
            }}
          />
        </CloseButtonWrapper>
        <Box sx={{ m: 1 }}>
          <ErrorOutlineIcon sx={{ fontSize: 32, color: INFO_COLOR }} />
        </Box>

        <Box>
          <Typography
            component="h2"
            id="modal-modal-title"
            variant="h5"
          >
            {dialogHook.content?.title}
          </Typography>
        </Box>
        <Box sx={{ m: 1.5 }}>
          <Typography
            id="modal-modal-description"
            sx={{
              mt: 2, maxWidth: 300, textAlign: 'center',
            }}
          >
            {dialogHook.content?.description}
            {dialogHook.linkHook ? (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <Link
                onClick={navigateAppointments}
                style={{ cursor: 'pointer' }}
              >
                {t('self_scheduling.initial_form.modal_link_change_app')}
              </Link>
            ) : null}
          </Typography>
        </Box>

        <ButtonWrapper sx={{ mb: 8 }}>
          {buttonText && (
          <NextButton
            onClick={dialogHook.content?.buttonHandler}
            text={buttonText}
          />
          )}
        </ButtonWrapper>
      </ModalContent>
    </Modal>
  )
}

const ModalContent = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  gap: 5px;
  padding: 5px 5px;
  min-width: 30rem;
`

const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const ButtonWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  width: 70%;
`

export default Dialog
