import { useEffect } from 'react'
import {
  Navigate,
  Outlet, createBrowserRouter, useLocation,
  useNavigate,
} from 'react-router-dom'
import Header from '../components/Header'
import NotFound from '../views/NotFound'
import ClinicInfo from '../views/selfScheduling/ClinicInfo'
import { SUPPORTED_LANGUAGES } from './constants'
import RescheduleConfirmation from '../views/selfScheduling/RescheduleConfirmation'
import ScheduledAppointmentInformation from '../views/selfScheduling/ScheduledAppointmentInformation'
import ContactForm from '../views/selfScheduling/ContactForm'
import Form from '../views/selfScheduling/Form'
import ClinicFinder from '../views/selfScheduling/ClinicFinder'
import AppointmentSelector from '../views/selfScheduling/AppointmentSelector'
import AppointmentDetails from '../views/selfScheduling/AppointmentDetails'
import AppointmentConfirmation from '../views/selfScheduling/AppointmentConfirmation'
import IdentifyPatient from '../views/selfScheduling/IdentifyPatient'
import AppointmentCancelled from '../views/selfScheduling/AppointmentCancelled'

const LayoutComponent = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const pathSegments = pathname.split('/').filter(Boolean) // Split and remove empty segments
    const firstLang = pathSegments[0]
    const secondLang = pathSegments[1]

    if (Object.keys(SUPPORTED_LANGUAGES).includes(firstLang) && firstLang === secondLang) {
      // If the first and second segments are both languages and match, remove the duplicate
      const newPath = `/${pathSegments.slice(1).join('/')}` // Create new path without the first language
      navigate(newPath, { replace: true }) // Redirect to the new path
    }
  }, [pathname, navigate])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <>
      <Header />
      <Outlet />
    </>
  )
}

const NotFoundRoot = () => (
  <>
    <Header />
    <NotFound />
  </>
)

const router = createBrowserRouter([
  {
    path: '/:lang?/*',
    element: <LayoutComponent />,
    errorElement: <NotFoundRoot />,
    children: [
      // Redirect rule for duplicate languages
      {
        path: ':lang/:lang/*',
        element: <Navigate
          replace
          to=".."
        />,
      },
      {
        path: '',
        element: <Form />,
        errorElement: <NotFound />,
      },
      {
        path: 'contact-form',
        element: <ContactForm />,
        errorElement: <NotFound />,
      },
      {
        path: 'find-clinic',
        element: <ClinicFinder />,
        errorElement: <NotFound />,
      },
      {
        path: 'select-appointment',
        element: <AppointmentSelector />,
        errorElement: <NotFound />,
      },
      {
        path: 'details-appointment',
        element: <AppointmentDetails />,
        errorElement: <NotFound />,
      },
      {
        path: 'confirm-appointment',
        element: <AppointmentConfirmation />,
        errorElement: <NotFound />,
      },
      {
        path: 'clinic-info',
        element: <ClinicInfo />,
        errorElement: <NotFound />,
      },
      {
        path: 'reschedule',
        element: <IdentifyPatient />,
        errorElement: <NotFound />,
      },
      {
        path: 'scheduled-appointment-information',
        element: <ScheduledAppointmentInformation />,
        errorElement: <NotFound />,
      },
      {
        path: 'appointment-cancelled',
        element: <AppointmentCancelled />,
        errorElement: <NotFound />,
      },
      {
        path: 'confirm-reschedule',
        element: <RescheduleConfirmation />,
        errorElement: <NotFound />,
      },
      {
        path: '*',
        element: <Navigate
          replace
          to="/not-found"
        />,
      },
    ],
  },
  {
    path: '/not-found',
    element: <NotFoundRoot />,
  },
])

export default router
