import axios from 'axios'
import { SS_ENDPOINT } from '../app/constants'

async function getPatientAppointments(data: any) {
  const URL = `${SS_ENDPOINT}/api/get-patient-appointments`

  try {
    const resp = await axios.post(URL, data)
    return resp.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default getPatientAppointments
