import { Box, Link, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ReactGA from 'react-ga4'
import { INFO_COLOR, SUB_COLOR } from '../../../app/constants'
import clearStorage from '../../../app/clear-storage'
import AppBar from '../../../components/AppBar'
import ViewContainer from '../../../components/ViewContainer'
import ContactNumber from '../../../components/ContactNumber'
import checkRequest from '../../../utils/checkRequest'
import usePathTranslation from '../../../hooks/pathTranslation'

const AppointmentConfirmation = () => {
  const { t } = usePathTranslation()
  const [patientName, setPatientName] = useState('')
  const patientNumber = useLocation().state?.patientNumber

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const personDetails = useSelector((state: any) => state.formInfo.PersonDetails)
  const zip = useSelector((state: any) => state.zipCode.zip)
  const guardian = useSelector((state: any) => state.formInfo.Guardian)
  const appointment = useSelector((state: any) => state.appointmentInfo)

  const data = {
    PersonDetails: personDetails,
    Guardian: guardian,
    Appointment: appointment,
    ZipCode: zip,
  }

  useEffect(() => {
    if (!checkRequest(data)) {
      navigate('../', { relative: 'path' })
    } else {
      if (personDetails?.Name?.First) {
        setPatientName(personDetails?.Name?.First)
      }
      clearStorage(dispatch)
      ReactGA.event({
        category: 'User',
        action: 'Appointment confirmed',
      })
    }
  }, [])

  return (
    <ViewContainer>
      <AppBar
        maxProgress={0}
        progress={0}
        title=""
      />
      <Box>
        <Typography
          sx={{
            fontSize: '24px',
            marginBottom: '15px',
          }}
        >
          {t('self_scheduling.appointment_confirmation.see_you_soon')}
          {' '}
          <Box
            component="span"
            sx={{
              color: INFO_COLOR,
              fontSize: '24px',
            }}
          >
            {patientName}
          </Box>
          !
        </Typography>
      </Box>
      <Box>
        <StyledText
          sx={{
            color: SUB_COLOR,
            fontStyle: 'italic',
          }}
        >
          {t('self_scheduling.appointment_confirmation.see_you_soon')}
        </StyledText>
      </Box>
      <DividingLine />
      <Box>
        <StyledText>
          {t('self_scheduling.appointment_confirmation.description')}
        </StyledText>
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: '24px',
            marginTop: '20px',
          }}
        >
          {t('self_scheduling.appointment_confirmation.patient_number')}
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={{
            color: INFO_COLOR,
            fontSize: '24px',
            fontWeight: 'bold',
            marginTop: '0px',
          }}
        >
          {patientNumber}
        </Typography>
        <Typography
          sx={{
            fontSize: '15px',
            marginTop: '20px',
          }}
        >
          <Link
            href="https://www.cranialtech.com"
            rel="noreferrer"
            target="_blank"
          >
            www.cranialtech.com
          </Link>
        </Typography>
      </Box>
      <ContactNumber />
    </ViewContainer>
  )
}

const StyledText = styled(Typography)`
  font-size: 16px;
  margin: 0px;
  padding: 0px;
`

const DividingLine = styled.div`
  border-top: 1px solid #e8e8e8;
  margin: 20px 0px;
  width: 100%;
`

export default AppointmentConfirmation
