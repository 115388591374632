import axios from 'axios'
import { SS_ENDPOINT } from '../app/constants'

const PostScheduleAppointment = (data: any) => async () => {
  const URL = `${SS_ENDPOINT}/api/schedule-appointment`

  const response = await axios.post(URL, data)

  return response.data.patientNumber
}
export default PostScheduleAppointment
