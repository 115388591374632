import axios from 'axios'
import { SS_ENDPOINT } from '../app/constants'

async function getAppointmentDuration(language: string) {
  const URL = `${SS_ENDPOINT}/api/get-appointment-duration?language=${language}`

  try {
    const resp = await axios.get(URL)
    return resp.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default getAppointmentDuration
