import {
  Box,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import styled from '@emotion/styled'
import {
  GoogleMap,
  InfoWindowF,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api'
import axios from 'axios'
import {
  useEffect, useState, useContext, createContext,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ERROR_COLOR, MAX_LENGTH_ZIP_CODE, SECONDARY_COLOR, DEFAULT_MAP_CENTER,
  SS_ENDPOINT,
} from '../../../app/constants'
import { setZipCode } from '../../../store/zipCode'
import { setServiceCenterID } from '../../../store/appointmentInfo'
import { setServiceCenterInfo } from '../../../store/serviceCenterInfo'
import NextButton from '../../../components/NextButton'
import { IMap, IMarker } from '../../../app/types'
import BackButton from '../../../components/BackButton'
import PlaceholderComponent from '../../../components/FormPlaceholderComponent'
import usePathTranslation from '../../../hooks/pathTranslation'

export const NextButtonContext = createContext<(isDisabled: boolean) => void>(() => { })

const MapComponent = ({
  addressString,
  serviceCenterId,
  isNextButtonDisabled,
  handleOnClickBack,
  handleSubmit,
}: IMap) => {
  const { t } = usePathTranslation()
  const [zipcodeInput, setZipcodeInput] = useState<string>(addressString || '')
  const [activeInfoWindow, setActiveInfoWindow] = useState<string | null>(null)
  const [serviceCenters, setServiceCenters] = useState<
  Array<IMarker> | undefined
  >([])
  const [errorMessage, setErrorMessage] = useState<string>('none')
  const [zipcodeValidation, setZipcodeValidation] = useState<string>('none')
  const [clinicValue, setClinicValue] = useState<string>('')
  const [center, setCenter] = useState(DEFAULT_MAP_CENTER)
  const [zoom, setZoom] = useState(4)
  const nextButtonRef = useContext(NextButtonContext)
  const Guardian1Email = useSelector((
    state: any,
  ) => state.formInfo.Guardian?.Guardian1Email || state.reschedule.personID)
  const dispatch = useDispatch()
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
  })

  async function getData() {
    let markers
    try {
      if (zipcodeInput !== '' && zipcodeInput) {
        const response = await axios.get(
          `${SS_ENDPOINT}/api/get-service-centers?address_string=${zipcodeInput}&email=${Guardian1Email}`,
        )
        markers = response.data?.ServiceCenters.map((serviceCenter: any) => ({
          position: {
            lat: Number(serviceCenter.Coords[1]),
            lng: Number(serviceCenter.Coords[0]),
          },
          label: `${
            serviceCenter.ServiceCenter.Name
          }, ${serviceCenter.DistanceMiles.toFixed(2)} ${t('self_scheduling.clinic_finder.miles_away')}`,
          draggable: true,
          centerID: serviceCenter.ServiceCenter.ID,
          name: serviceCenter.ServiceCenter.Name,
          distance: serviceCenter.DistanceMiles.toFixed(2),
          address: `${serviceCenter.ServiceCenter.Address.Line1} ${serviceCenter.ServiceCenter.Address.Line2}`,
          value: `${
            serviceCenter.ServiceCenter.Name
          }, ${serviceCenter.DistanceMiles.toFixed(2)} ${t('self_scheduling.clinic_finder.miles_away')}`,
        }))
        if (response.data?.ServiceCenters.length > 0) {
          setZipcodeValidation('none')
          setCenter({
            lat: response.data?.ServiceCenters[0].Coords[1],
            lng: response.data?.ServiceCenters[0].Coords[0],
          })
          setZoom(10)
          nextButtonRef(true)
        } else {
          setZipcodeValidation('block')
          setCenter({
            lat: response.data?.CoordsAddress[1],
            lng: response.data?.CoordsAddress[0],
          })
          setErrorMessage(t('self_scheduling.clinic_finder.error_message_clinic', {
            zipcodeInput,
          }))
          setZoom(6)
        }
        setServiceCenters(markers)
      } else {
        setZipcodeValidation('none')
      }
    } catch (e: any) {
      setZipcodeValidation('block')
      setErrorMessage(e.response?.data)
      setServiceCenters([])
      setZoom(4)
      setCenter({
        lat: 39.06380844,
        lng: -94.89698792,
      })
    }
  }

  useEffect(() => {
    if (zipcodeInput.length === MAX_LENGTH_ZIP_CODE) {
      getData()
      setClinicValue('')
      dispatch(setZipCode(zipcodeInput))
    }
  }, [zipcodeInput])

  // load service center info if serviceCenterId is passed in
  useEffect(() => {
    if (serviceCenterId) {
      const serviceCenter = serviceCenters?.find(
        (item) => item.centerID === serviceCenterId,
      )
      if (serviceCenter) {
        setClinicValue(serviceCenter.value)
        setCenter(serviceCenter.position)
        setCenterValues(serviceCenter.centerID, serviceCenter.name, serviceCenter.address)
        nextButtonRef(false)
      }
    }
  }, [serviceCenters])

  const markerClicked = (marker: IMarker, index: string | null) => {
    setActiveInfoWindow(index)
    setClinicValue(marker.value)
    setCenter(marker.position)
    setCenterValues(marker.centerID, marker.name, marker.address)
    nextButtonRef(false)
  }

  const setCenterValues = (
    centerID: number,
    nameCenter: string,
    addressCenter: string,
  ) => {
    dispatch(setServiceCenterID(centerID))
    dispatch(
      setServiceCenterInfo({
        Name: nameCenter,
        Address: addressCenter,
      }),
    )
  }

  const handleSelectChange = (value: string) => {
    setActiveInfoWindow(value)
    setClinicValue(value)
    setCenter(serviceCenters?.find((item) => item.value === value)?.position || DEFAULT_MAP_CENTER)
    setCenterValues(
      serviceCenters?.find((item) => item.value === value)?.centerID || 0,
      serviceCenters?.find((item) => item.value === value)?.name || '',
      serviceCenters?.find((item) => item.value === value)?.address || '',
    )
    nextButtonRef(false)
  }

  return isLoaded ? (
    <>
      <Grid
        columnSpacing={2}
        container
        rowSpacing={2}
      >
        <Grid
          desktop={12}
          item
          mobile={12}
          tablet={12}
        >
          <Box
            alignContent="left"
            display="flex"
            sx={{ paddingBottom: '10px' }}
          >
            <Typography>{t('self_scheduling.clinic_finder.title_zip')}</Typography>
            <Typography
              color="error"
              marginLeft="5px"
            >
              *
            </Typography>
          </Box>
          <TextField
            autoComplete="off"
            color="info"
            fullWidth
            onChange={(e) => {
              if (e.target.value.length <= MAX_LENGTH_ZIP_CODE) {
                setZipcodeInput(e.target.value)
              }
            }}
            placeholder={t('self_scheduling.clinic_finder.placeholder_zip')}
            size="small"
            type="number"
            value={zipcodeInput}
            variant="outlined"
          />
          <StyledValidationError style={{ display: zipcodeValidation }}>
            {errorMessage}
          </StyledValidationError>
        </Grid>
        <Grid
          desktop={12}
          item
          mobile={12}
          tablet={12}
        >
          <Box
            alignContent="left"
            display="flex"
            sx={{ paddingBottom: '10px' }}
          >
            <Typography>{t('self_scheduling.clinic_finder.title_clinic')}</Typography>
            <Typography
              color="error"
              marginLeft="5px"
            >
              *
            </Typography>
          </Box>
          <Select
            color="info"
            disabled={serviceCenters?.length === 0}
            displayEmpty
            name="select-clinic"
            onChange={(e) => handleSelectChange(e.target.value)}
            renderValue={(rValue) => (rValue !== '' && serviceCenters ? (
              serviceCenters.find((item) => item.value === rValue)?.label
            ) : (
              <PlaceholderComponent
                placeholder={t('self_scheduling.clinic_finder.placeholder_clinic')}
              />
            ))}
            size="small"
            style={{ width: '100%', textAlign: 'left' }}
            value={clinicValue || ''}
          >
            {serviceCenters
              ? serviceCenters.map((item: any) => (
                <MenuItem
                  key={item.value}
                  value={item.value}
                >
                  {item.label}
                </MenuItem>
              ))
              : []}
          </Select>
        </Grid>
      </Grid>
      <StyledMap>
        <GoogleMap
          center={center}
          mapContainerStyle={containerStyle}
          options={{
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            zoomControl: false,
          }}
          zoom={zoom}
        >
          {serviceCenters?.map((serviceCenter) => {
            if (serviceCenter?.position) {
              const index = serviceCenter.label
              const isActiveMarker = activeInfoWindow === index
              return (
                <Box key={index}>
                  <Marker
                    key={index}
                    icon={{
                      fillColor: isActiveMarker ? SECONDARY_COLOR : '#00BBA3',
                      path: google.maps.SymbolPath.CIRCLE,
                      scale: 9,
                      strokeColor: 'white',
                      fillOpacity: 1,
                      strokeWeight: 1,
                      strokeOpacity: 0.5,
                    }}
                    onClick={() => markerClicked(serviceCenter, index)}
                    position={serviceCenter.position}
                  />
                  {isActiveMarker && (
                  <Marker
                    key={`${index}selected`}
                    icon={{
                      fillColor: SECONDARY_COLOR,
                      path: google.maps.SymbolPath.CIRCLE,
                      scale: 18,
                      strokeColor: SECONDARY_COLOR,
                      fillOpacity: 0.5,
                      strokeWeight: 0.5,
                      strokeOpacity: 0.5,
                    }}
                    onClick={() => markerClicked(serviceCenter, index)}
                    position={serviceCenter.position}
                  />
                  )}
                  {isActiveMarker && (
                    <InfoWindowF
                      onCloseClick={() => {
                        setActiveInfoWindow(null)
                      }}
                      position={serviceCenter.position}
                    >
                      <>
                        <h3>{serviceCenter.name}</h3>
                        <b>
                          {t('self_scheduling.clinic_finder.distance_miles', {
                            distance: serviceCenter.distance,
                          })}
                        </b>
                        <br />
                        <br />
                        <p>{serviceCenter.address}</p>
                      </>
                    </InfoWindowF>
                  )}
                </Box>
              )
            }
            return null
          })}
        </GoogleMap>
      </StyledMap>
      <ButtonWrapper>
        <NextButton
          isDisabled={isNextButtonDisabled}
          onClick={handleSubmit}
          secondary
          text={t('self_scheduling.clinic_finder.next')}
        />
        <BackButton onClick={handleOnClickBack} />
      </ButtonWrapper>
    </>
  ) : null
}
const containerStyle = {
  width: '100%',
  height: '550px',
}

const StyledValidationError = styled.div`
  text-align: left;
  font-size: 11px;
  font-weight: 400;
  color: ${ERROR_COLOR};
  margin-top: 3px;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`

const StyledMap = styled(Box)`
  height: 550px;
  margin-top: 30px;
`

export default MapComponent
