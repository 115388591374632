import { Grid, Typography, ToggleButton } from '@mui/material'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import ReactGA from 'react-ga4'
import { getWeekDates } from './utils'
import { IDayPicker, ITimePicker } from '../../../app/types'
import {
  DATE_PATTERNS,
  PRIMARY_COLOR,
  PRIMARY_COLOR_LIGHT,
  SUB_COLOR,
  INFO_COLOR,
  INFO_COLOR_LIGHT,
} from '../../../app/constants'
import usePathTranslation from '../../../hooks/pathTranslation'

dayjs.extend(customParseFormat)
const WEEKS_IN_FUTURE = 2

export const DayPicker = (appointmentSelectorProps: IDayPicker) => {
  const {
    selectedDate,
    setSelectedDate,
    date,
    setDate,
    setSelectedTime,
    monthHeader,
    availableTimes,
  } = appointmentSelectorProps

  // update the date selected
  const handleSelectedDate = (e: any) => {
    ReactGA.event({
      category: 'User',
      action: 'Appointment Slot selected',
    })
    setSelectedDate(e.target.value)
    setSelectedTime('')
  }

  // update week dates displayed
  const handleTapLeft = () => {
    const previousWeekDay = date.subtract(1, 'week')
    // get last day of previous week
    const lastDay = previousWeekDay.endOf('week')

    // if the last day of the previous week is in the past, ignore tap
    if (lastDay.isBefore(dayjs())) {
      return
    }

    setDate(previousWeekDay)
    setSelectedTime('')
  }

  const handleTapRight = () => {
    const nextWeekDay = date.add(1, 'week')
    const threeWeeksFromNow = dayjs().add(WEEKS_IN_FUTURE, 'weeks')
    if (nextWeekDay.isAfter(threeWeeksFromNow)) {
      return
    }
    setDate(nextWeekDay)
    setSelectedTime('')
  }

  return (
    <Grid
      container
      sx={{
        justifyContent: 'center',
        paddingBottom: '1rem',
      }}
    >
      <Grid container>
        <Grid
          item
          mobile={1}
          style={{
            display: 'flex',
            alignSelf: 'center',
            justifyContent: 'center',
          }}
        >
          <ArrowBackIos
            onClick={handleTapLeft}
            style={{ fontSize: '1.5rem', cursor: 'pointer' }}
          />
        </Grid>
        <Grid
          item
          mobile={10}
        >
          <Title>
            {' '}
            {monthHeader}
            {' '}
          </Title>
        </Grid>
        <Grid
          item
          mobile={1}
          style={{
            display: 'flex',
            alignSelf: 'center',
            justifyContent: 'center',
          }}
        >
          <ArrowForwardIos
            onClick={handleTapRight}
            style={{ fontSize: '1.5rem', cursor: 'pointer' }}
          />
        </Grid>
      </Grid>

      <Grid
        columnSpacing={1}
        container
        rowSpacing={1}
        sx={{
          justifyContent: 'center',
          height: 'fit-content',
        }}
      >
        {getWeekDates(date).map((_date: dayjs.Dayjs) => {
          const fullDate = _date.format(DATE_PATTERNS.iso_8601_date)
          const day = _date.format(DATE_PATTERNS.name_of_week)
          const dayNum = _date.format(DATE_PATTERNS.day_of_month)
          return (
            <Grid
              key={day}
              item
            >
              <Typography style={{ color: SUB_COLOR }}>{day}</Typography>
              <CircularRadioButton
                disabled={!availableTimes[fullDate]}
                onChange={handleSelectedDate}
                selected={selectedDate === fullDate}
                value={fullDate}
              >
                {dayNum}
              </CircularRadioButton>
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}

export const TimePicker = (timePickerProps: ITimePicker) => {
  const { t } = usePathTranslation()
  const {
    selectedDate, setSelectedTime, availableTimes, selectedTime,
  } = timePickerProps
  const handleSelectedTime = (e: any) => {
    setSelectedTime(e.target.value)
  }

  return (
    <Grid
      container
      sx={{
        justifyContent: 'center',
        maxHeight: '41vh',
        overflow: 'auto',
      }}
    >
      {/* AM */}
      <Grid item>
        <Title>AM</Title>
      </Grid>
      <Grid
        container
        rowSpacing={1}
        sx={{ justifyContent: 'center' }}
      >
        {availableTimes[selectedDate]
          .filter((time: string) => time < '12:00')
          .map((time: string) => {
            const startTime = dayjs(time, 'HH:mm')
            return (
              <Grid
                key={time}
                item
                mobile={12}
              >
                <PillRadioButton
                  onChange={handleSelectedTime}
                  selected={selectedTime === time}
                  value={time}
                >
                  {startTime.format(DATE_PATTERNS.time)}
                  {' '}
                </PillRadioButton>
              </Grid>
            )
          })}
        {!availableTimes[selectedDate].filter((time: string) => time < '12:00')
          .length && (
          <Typography>
            {t('self_scheduling.appointment_selector.no_available_appointments')}
          </Typography>
        )}
      </Grid>

      {/* PM */}
      <Grid item>
        <Title>PM</Title>
      </Grid>
      <Grid
        container
        rowSpacing={1}
        sx={{ justifyContent: 'center' }}
      >
        {availableTimes[selectedDate]
          .filter((time: string) => time >= '12:00')
          .map((time: string) => {
            const startTime = dayjs(time, 'HH:mm')
            return (
              <Grid
                key={time}
                item
                mobile={12}
              >
                <PillRadioButton
                  onChange={handleSelectedTime}
                  selected={selectedTime === time}
                  value={time}
                >
                  {startTime.format(DATE_PATTERNS.time)}
                  {' '}
                </PillRadioButton>
              </Grid>
            )
          })}
        {!availableTimes[selectedDate].filter((time: string) => time >= '12:00')
          .length && (
          <Typography>
            {t('self_scheduling.appointment_selector.no_available_appointments')}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

const CircularRadioButton = styled(ToggleButton)`
  border-radius: 50%;
  border: none;
  height: 2.5rem;
  width: 2.5rem;
  background-color: ${INFO_COLOR_LIGHT};
  color: ${INFO_COLOR};

  &:hover {
    background-color: ${INFO_COLOR_LIGHT};
    border: 1px solid ${INFO_COLOR};
  }

  &.Mui-selected,
  &.Mui-selected:hover {
    background-color: ${INFO_COLOR};
    color: white;
  }

  &.Mui-disabled {
    border: none;
    background-color: white;
  }
`

const PillRadioButton = styled(ToggleButton)`
  background-color: ${PRIMARY_COLOR_LIGHT};
  height: 40px;
  width: 100%;
  max-width: 350px;
  color: ${PRIMARY_COLOR};
  font-weight: 600;
  border: none;
  border-radius: 24px;

  &:hover {
    background-color: ${PRIMARY_COLOR_LIGHT};
    border: 1px solid ${PRIMARY_COLOR};
  }

  &.Mui-selected,
  &.Mui-selected:hover {
    background-color: ${PRIMARY_COLOR};
    color: white;
  }
`

const Title = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  margin: 1rem 0;
`
